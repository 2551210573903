import React from "react";
import { Prism as Highlighter } from "react-syntax-highlighter";

const code = `query ProjectsByName($name: String!) {
  searchProjects(name: $name) {
    id
    name
  }
}`;

export const ConnectionQueryWithArgumentsSnippet = () => {
  return (<Highlighter language="graphql" wrapLines={true}>
    {code}
  </Highlighter>);
};
