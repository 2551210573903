import React from 'react'

import {
    CodeSurferColumns,
} from "code-surfer";
import { usePrismGqlAlias } from "../hooks/PrismGqlAlias";

import Prism from 'prismjs'

// Work around "graphql" being a big no-no-word for gatsby and mdx
// by adding an alias we can use to make prism highlight graphql code
export const GqlCodeSurferColumns = ({children}) => {
    usePrismGqlAlias(Prism);

    return <CodeSurferColumns>{children}</CodeSurferColumns>;
}
