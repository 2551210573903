import React from "react";
import { Prism as Highlighter } from "react-syntax-highlighter";

const code = `
const {data, error} = useAllTimeEntriesQuery();

if (error) {
  throw error;
}

console.log(data?.timeEntries);
`;

export const QueryHookSnippet = () => {
  return (<Highlighter language="tsx" wrapLines={true}>
    {code}
  </Highlighter>);
};
