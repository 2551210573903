import React from "react";
import { Prism as Highlighter } from "react-syntax-highlighter";

const code = `
const apolloClient = new ApolloClient({
  uri: "http://localhost:4000",
  cache: new InMemoryCache(),
});
`;


export const ApolloCacheSnippet = () => {
  return (<Highlighter language="tsx" wrapLines={true}>
    {code}
  </Highlighter>);
};
