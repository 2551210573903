// You found my secret stash of lowercase graphqls. Don't tell Gatsby 🙊
// https://github.com/jxnblk/mdx-deck/issues/650

export const getLowercaseGraphQl = () => {
    return 'graphql'
}

export const LowercaseGraphQl = () => {
    // Get low 🕺
    return getLowercaseGraphQl();
}
