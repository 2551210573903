import React from "react";
import { Prism as Highlighter } from "react-syntax-highlighter";

const code = `query AllProjectsWithTimeEntries {
  projects {
    id
    name
    timeEntriesConnection {
      edges {
        node {
          id
          comment
        }
      }
    }
  }
}`;

export const ConnectionQuerySnippet = () => {
  return (<Highlighter language="graphql" wrapLines={true}>
    {code}
  </Highlighter>);
};
