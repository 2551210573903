import React from 'react'

import {
    CodeSurfer,
} from "code-surfer";
import { usePrismGqlAlias } from "../hooks/PrismGqlAlias";

import Prism from 'prismjs'

// Work around "graphql" being a big no-no-word for gatsby and mdx
// by adding an alias we can use to make prism highlight graphql code
export const GqlCodeSurfer = ({children}) => {
    usePrismGqlAlias(Prism)

    return <CodeSurfer>{children}</CodeSurfer>;
}
