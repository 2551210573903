import React from "react";
import { Prism as Highlighter } from "react-syntax-highlighter";

const code = `
const [logTimeMutation, {data, error}] = useLogTimeMutation();

logTimeMutation({
  variables: {
    comment: 'Trying out some mutations',
    projectId: 'workshop',
    start: '2022-01-11T18:00:00.000Z',
    end: '2022-01-11T19:00:00.000Z'
  }
});

if (error) {
  throw error;
}

console.log(data?.logTime.id);
`;

export const MutationHookSnippet = () => {
  return (<Highlighter language="tsx" wrapLines={true}>
    {code}
  </Highlighter>);
};
