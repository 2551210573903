import React from "react";
import { Prism as Highlighter } from "react-syntax-highlighter";

const code = `import { gql } from "@apollo/client";

gql\`
  mutation LogTime($comment: String!, $projectId: String!, $start: Date!, $end: Date!) {
    addTimeEntry(comment: $comment, projectId: $projectId, start: $start, end: $end) {
      id
    }
  }
\`;`;

export const DummyMutationSnippet = () => {
  return (<Highlighter language="tsx" wrapLines={true}>
    {code}
  </Highlighter>);
};
